@font-face {
  font-family: 'OperatorMono-BookItalic';
  src: url('OperatorMono-BookItalic.eot');
  src: url('OperatorMono-BookItalic.eot?#iefix') format('embedded-opentype'),
    url('OperatorMono-BookItalic.svg#OperatorMono-BookItalic') format('svg'),
    url('OperatorMono-BookItalic.ttf') format('truetype'),
    url('OperatorMono-BookItalic.woff') format('woff'),
    url('OperatorMono-BookItalic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

.operator-mono {
  font-family: 'OperatorMono-BookItalic';
}
