@layer utilities {
  @variants responsive {

    .page-break-inside-avoid {
      page-break-inside: avoid;
    }

    .page-break-after-avoid {
      page-break-after: avoid;
    }

    .page-break-before-avoid {
      page-break-before: avoid;
    }
  }
}

