.hyphenate {
  hyphens: auto;
}

.green-icon {
  color: linear-gradient(45deg, hsl(143, 55, 62), hsl(173, 55, 62));
}

.title {
  @apply text-gray-700 tracking-wide font-medium;
}

.subtitle {
  @apply text-indigo-500;
}
