@layer components {

  .section {
    @apply mt-10 mb-6;

    &:last-child {
      @apply mb-0;
    }
  }
}
