@layer utilities {
  @variants hover, group-hover {

    .blur-0 {
      filter: blur(0px);
    }

    .blur-1 {
      filter: blur(10px);
    }

    .pop-out {
      transition: 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate(0px, -10px);
    }

    .pop-in {
      transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      transform: translate(0px) scale(0.95);
    }
  }

  .cursor-help {
    cursor: help;
  }
}

