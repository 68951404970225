





































































































.social-icon-link {
  @apply inline-block flex mr-3 text-indigo-800 print:block print:items-baseline print:mr-0 print:text-gray-900 print:space-x-2 print:space-y-2 hover:text-gray-200 dark:text-gray-900 dark:hover:text-gray-600;
}
