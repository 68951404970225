// Links

a {
  transition: all 0.2s ease-out 0s;
}

// Greyscale
@layer utilities {
  @variants dark {

    .greyscale-1 {
      filter: grayscale(1);

      &:hover {
        filter: grayscale(0);
      }
    }

  }
}

// Logo

.logo {
  max-height: 40px;

  @screen lg {
    max-height: 40px;
  }
}

// Pop Out Quick

.pop-out-quick-enter-active,
.pop-out-quick-leave-active {
  transition: all 0.4s ease-in-out;
}

.pop-out-quick-enter,
.pop-out-quick-leave {
  opacity: 0;
  transform: translateY(-7px);
}
