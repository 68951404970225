.social-icon-link{
  display: inline-block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(67, 65, 144, var(--tw-text-opacity))
}

.social-icon-link:hover{
  --tw-text-opacity: 1;
  color: rgba(237, 242, 247, var(--tw-text-opacity))
}

@media print{
  .social-icon-link > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .social-icon-link{
    display: block;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    margin-right: 0;
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity))
  }
}

@media (prefers-color-scheme: dark){
  .social-icon-link{
    --tw-text-opacity: 1;
    color: rgba(26, 32, 44, var(--tw-text-opacity))
  }

  .social-icon-link:hover{
    --tw-text-opacity: 1;
    color: rgba(113, 128, 150, var(--tw-text-opacity))
  }
}
